import React from 'react'
import { Link } from 'gatsby'
import { css } from 'glamor'

import Layout from '../components/layout'
import Footer from '../components/footer'

const CV = () => (
  <Layout>
    <article className={`${contentStyle}`}>
      <h1>Curriculum vitae</h1>
      <p>Welcome to the CV</p>
      <Link to='/'>Go to the homepage</Link>
    </article>
    <Footer />
  </Layout>
)

export default CV

let contentStyle = css({
  label: 'content',
  flex: 1,
  padding: '5%',
})